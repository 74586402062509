import {get,post} from '../request';

//新建 APP 拉起参数
export const keypearArg = (params) => post(`/api/v1/keypear/app/arg`,params)

//APP 操作结果查询
export const keypearResult = (params) => get(`/api/v1/keypear/app/login?id=${params}`)

//APP 操作结果查询
export const getIssuer = (params) => get(`/api/v1/keypear/issuer`,params)
