import axios from 'axios'
import config from './config.js'
import router from "../router"
import {
  Message
} from 'element-ui';
// const baseURL = process.env.VUE_APP_BASE_API
// let baseURL = ''
let baseURL = '/zzcRequest'
if (process.env.NODE_ENV == 'production') {
  baseURL = config.zqcRequest
}

// 创建 axios 实例
const service = axios.create({
  baseURL,
  timeout: 150000,
  headers: {
    'Content-Type': 'application/json'
  }
  // 配置项
})

// request拦截器
service.interceptors.request.use(
  (config) => {
    // 获取token
    const userInfo = JSON.parse(localStorage.getItem('user'))
    const accessToken = userInfo && userInfo.token || ''
    if (accessToken) {
      // config.headers['authorization'] = `Bearer ${accessToken}` // 让每个请求携带自定义token 请根据实际情况自行修改
      // config.headers['Authorization'] = `Basic dHJlYXN1cnk6dDAwMUpLTFMwOThaIyFBQEZH` // 让每个请求携带自定义token 请根据实际情况自行修改
      config.headers['X-Token'] = userInfo.token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
// 响应拦截器
service.interceptors.response.use(
  response => {
    if (response.status === 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  error => {
    if (error.response) {
      if (error.response.data.code == 4004 || error.response.data.code == 401) {

        router.push({
          path: '/login',
        })
        localStorage.removeItem('flow')
        localStorage.removeItem('flowList')
        localStorage.removeItem('user')
        localStorage.removeItem('userInfo')
      }
      return Promise.reject(error.response);
    }


  }
);
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params, config) {
  return new Promise((resolve, reject) => {
    service.get(url, {
      params,
    }, {
      ...config
    })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        if (err.status == 400 || (err.status == 404 && err.data.code == 4101)) {
          resolve(err.data);
        } else {
          Message.error(err)
        }
      })
  });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params, config = {}) {
  return new Promise((resolve, reject) => {
    service.post(url, params, {
      ...config
    }).then(res => {
      resolve(res.data);
    }).catch(err => {
      if (err.status == 400) {
        resolve(err.data);
      } else {
        // Message.error(err.response.data.msg)
        Message.error(err)
      }
    })
  });
}

export function det(url, params, config = {}) {
  return new Promise((resolve, reject) => {
    service.delete(url, params, {
      ...config
    }).then(res => {
      resolve(res.data);
    }).catch(err => {
      if (err.status == 400) {
        resolve(err.data);
      } else {
        Message.error(err.data.msg)
        // Message.error(err.response.data.msg)
      }
    })
  });
}

export function downloadFile(url, params, method = 'get') {
  return new Promise((resolve, reject) => {
    let config = {
      url,
      method,
      responseType: 'blob'
    }
    if (method == "get") {
      config["params"] = params;
    } else {
      config["data"] = params;
    }
    service(config).then(res => {
      resolve(res.data);
    })
      .catch(err => {
        if (err.data.type === 'application/json') {
          const reader = new FileReader()
          reader.readAsText(err.data)
          reader.onload = e => {
            console.log(e)
            const {
              msg
            } = JSON.parse(e.target.result)
            Message.error(msg)
          }
        }
      })
  });
}
